<template>
  <div class="home">
    <div style="margin-top:1rem;"></div>

    <home-main> </home-main>
    
  </div>
</template>

<script>
import homeMain from '@/components/homeMain.vue'


export default {
  name: 'Home',
  components: {
    homeMain
  }
}
</script>
